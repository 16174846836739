import React from 'react'

// import layout
import WorkLayout from '../../layout/work-layout'

// components
import { StaticImage } from 'gatsby-plugin-image'
import ContactLink from '../../micro-components/contact-link'
import Seo from '../../utils/seo'

const Work = () => {
  // DEFINE THERE ALL THE WORK CONSTs
  const title = 'Villa Maria Paola',
    desc =
      'Riprese e montaggio video per Villa Maria Paola, centro medico specializzato a Firenze.',
    subtitle = '',
    roles = ['Videomaker'],
    videoSrc = [
      'https://www.youtube.com/watch?v=AC6S19S-rew',
      'https://www.youtube.com/watch?v=zGa04_RO26o',
    ],
    // put this TRUE only if there are logos to show
    logo = false

  return (
    <WorkLayout
      title={title}
      subtitle={subtitle}
      roles={roles}
      videoSrc={videoSrc}
      logo={logo}
    >
      <Seo title={title} description={desc}/>
      {/* COVER IMG */}
      <StaticImage
        src='../../images/cover-villa-maria.jpg'
        alt='Cover per il lavoro Villa Maria Paola'
        className='work-cover shadow-black'
      />
      {/* DESC */}
      <p className='work-description desc-1 margin-bottom'>
        <span>
          <em>Villa Maria Paola</em> è un’ampia ed elegante struttura situata in Via Atto
          Vannucci, a Firenze.
        </span>
        <span>
          Progetto in collaborazione con{' '}
          <ContactLink
            to='https://www.leonardocasalini.com/'
            external={true}
            title='Per il sito di Leonardo Casalini'
          >
            {' '}
            Leonardo Casalini
          </ContactLink>
          .
        </span>
      </p>
      {/* DESC n.2 */}
      <p className='work-description desc-2'>
        <span>
          Il centro si sviluppa su due piani, con la sala d’aspetto, la
          reception e gli ambulatori al primo piano e le sale per le prestazioni
          odontoiatriche (chirurgiche e ambulatoriali) al secondo piano,
          raggiungibile anche in ascensore.
        </span>
        <span>
          La struttura offre una gamma completa di servizi ambulatoriali,
          avvalendosi della collaborazione di professionisti affermati nei
          rispettivi campi.
        </span>
      </p>
    </WorkLayout>
  )
}

export default Work
